import React from "react"
import Container from "../components/layout/textFormatingComponents/Container"
import { graphql } from "gatsby"
import PrepaidCard from "../components/resultComponents/PrepaidCard"
import GlobalStyles from "../components/layout/GlobalStyles"
import Header1 from "../components/layout/textFormatingComponents/Header1"
import Header2 from "../components/layout/textFormatingComponents/Header2"
import Header3 from "../components/layout/textFormatingComponents/Header3"
import TextArea from "../components/layout/textFormatingComponents/TextArea"
import MetaDetails from "../components/seoComponents/MetaDetails"
import RowContainer from "../components/layout/textFormatingComponents/RowContainer"
import Button from "../components/layout/Button"

const FrontPage = ({ data }) => {
  const plansHook = data.allPrepaidJson.nodes
  const plans = plansHook.map(plan => {
    return (
      <PrepaidCard
        key={plan.id}
        img={plan.img}
        name={plan.name}
        price={plan.price}
        calls={plan.callPrice}
        texts={plan.textPrice}
        internet={plan.internet}
        speed={plan.internetSpeed}
        openSaldo={plan.openSaldo}
        url={plan.url}
        pageName={"prepaid"}
      />
    )
  })

  return (
    <GlobalStyles>
      <MetaDetails
        title="Valitse paras prepaid liittymä 2020 - Katso täältä!"
        desc="Prepaid liittymä on helppo käyttää ja sen avulla hallitset täysin puhelinlaskuasi. Katso täältä parhaat prepaid liittymät ja valitse sinulle sopivin!"
      />
      <Container>
        <Header1>Prepaid-liittymät</Header1>
        <TextArea>
          Prepaid on soviva liittymä kun tarvitset liittymää, josta maksat
          ainoastaan silloin kun käytät sitä. Prepaid saattaa olla kuitenkin
          kalliimpi kuin kuukausimaksullinen liittymä, etenkin internetin
          kustannusten kannalta. Tutustu alapuolelta parhaisiin prepaid
          liittymiin.
        </TextArea>
        <Header3>Valitse sopiva puhelinliittymä alapuolelta:</Header3>
        {plans}
        <RowContainer title={"Tutustu myös seuraaviin vertailuihin:"}>
          <Button link={"/lapselle/"} title={"Puhelinliittymä lapselle"} />
          <Button
            link={"/puhelinliittymavertailu/"}
            title={"Puhelinliittymävertailu"}
          />
          <Button link={"/mobiililaajakaista/"} title={"Mobiililaajakaista"} />
          <Button
            link={"/ilman-nettia/"}
            title={"Puhelinliittymä ilman nettiä"}
          />
        </RowContainer>
        <Header2>Prepaid liittymät</Header2>
        <TextArea>
          Prepaid-liittymä tarkoittaa liittymää, jossa puheaikaa ladataan
          ennakkoon liittymälle. Niihin on mahdollista valita myös erilaisia
          datapaketteja, jotka ovat käteviä etenkin paljon nettiä käyttäville.
          Prepaid-liittymiä pystyy ostamaan samaan tapaan kuin jälkikäteen
          laskutettaviakin liittymiä, eli esimerkiksi operaattoreiden
          verkkosivuilta tai liikkeistä, ja tämän lisäksi myös R-kioskeilta.
          Operaattoreista vain Moi Mobiililla ei ole valikoimissaan ollenkaan
          prepaid-liittymiä, vaan heillä kaikki laskutus tapahtuu käytön mukaan
          jälkikäteen. Muilta operaattoreilta löytyy sen sijaan useampia
          erilaisia prepaid-liittymiä – tarkastellaan niitä ja niiden eroja
          lähemmin seuraavissa kappaleissa.
        </TextArea>
        <TextArea>
          Saunalahti Prepaid -aloituspakkauksessa on kuudella eurolla puheaikaa.
          R-kioskien ja Elisan myymälän lisäksi liittymiä myyvät myös S- ja
          K-ryhmien kaupat sekä huoltoasemat Shell ja St1. Liittymän
          peruspalveluihin kuuluu puheluiden ja videopuheluiden, teksti- ja
          multimediaviestien sekä netin lisäksi muun muassa ryhmäpuhelu-,
          välipuhelu-, numeronnäytön esto- ja datapalvelu. Liittymää voi käyttää
          myös ulkomailla. Ennen ensimmäistä latausta liittymä on voimassa kolme
          kuukautta, ja jokaisen latauksen jälkeen 12 kuukautta. Runsaasti
          nettiä käyttäville on saatavilla myös erillisiä nettipaketteja.
          Liittymän normaali hinta on 5,90 euroa.
        </TextArea>
        <TextArea>
          Telian prepaid-liittymällä maksat puheluista ja viesteistä 0,066
          €/minuutti tai kappale ja netin käytöstä 0,01 €/megatavu,
          maksimiveloituksen ollessa kuitenkin 0,99 € päivässä. Liittymässä on
          ostohetkellä 7 eurolla alkusaldoa, ja lisäksi ensimmäisestä
          latauksesta saa 5 eurolla bonussaldoa, jota voi käyttää
          normaalihintaisiin puheluihin ja viesteihin sekä netin käyttöön.
          Aloituspakkauksessa ovat mukana kaikki SIM-korttikoot. Netin
          maksiminopeus on 100 megabittiä sekunnissa. Telian prepaid ei toimi
          ulkomailla, joten tämä kannattaa ottaa huomioon liittymää ostaessa.
          Telian prepaid-liittymään on mahdollista ladata myös erillisiä
          nettipaketteja.
        </TextArea>
        <TextArea>
          DNA Super Prepaid sisältää saldoa viidellä eurolla. Netin
          maksiminopeus on 4G-verkossa 300 megabittiä sekunnissa. Puhelut ja
          viestit maksavat kuusi senttiä minuutti/kappale. Netin käyttö maksaa
          0,99 euroa päivässä, riippumatta käytön määrästä.
          Verkkovierailupuheluista ja -tekstiviesteistä veloitetaan
          EU/ETA-alueella sama hinta kuin Suomessakin. DNA:lla on olemassa myös
          erillinen mobiilisovellus, jonka asentamalla saa käyttöönsä etuja,
          joita ei muuten saa. Sovelluksen tutustumisetuna saa yhden päivän
          ilmaisen datayhteyden. Liittymässä on myös erityinen turvaominaisuus,
          joka tarkoittaa, että vaikka saldo loppuisi, voit soittaa vielä
          kahteen ennalta asetettuun numeroon kolmen minuutin puhelut.
        </TextArea>
        <TextArea>
          DNA:n toinen prepaid-liittymä on nimeltään DNA Rajaton Prepaid, ja
          siinä veloitus tapahtuu päiväkohtaisesti. Hinta on 0,89 euroa
          päivässä, sisältäen rajattomat kotimaan sekä EU/ETA-alueen
          verkkovierailupuhelut ja -viestit sekä netin käytön Suomessa.
          Liittymälle tulee kuukaudessa hintaa yhteensä 26,70 euroa. Liittymä on
          myös mahdollista laittaa tauolle, mikäli tietää, että ei aio käyttää
          sitä pitkään aikaan. Tällöin saldo ei kulu turhaan, sillä muuten
          liittymää veloitetaan riippumatta siitä, käyttääkö puhelinta vai ei.
          Netin käyttö maksaa EU/ETA-alueella 0,0056 euroa megatavulta.
        </TextArea>
        <Header3>
          Mikä ihmeen roaming – pitääkö puhelin sammuttaa ulkomailla?
        </Header3>
        <TextArea>
          Vaikka tietoisuus puhelinliittymien ominaisuuksista, samoin kuin
          puhelintenkin ominaisuuksista, on kasvanut, ja matkailu on yhä
          suositumpaa, ei kaikille välttämättä edelleenkään ole täysin selvää,
          mikä on roaming, johon törmää aina matkustaessa. Puhelinliittymistä
          puhuttaessa se kuuluu melko olennaisesti asiaan, joten perehdytäänpä
          siihen hiukan. Verkkovierailu, eli roaming, ei ole enää samanlainen
          mörkö kuin takavuosina, jolloin monesti oli todella järkevämpää
          sammuttaa koko puhelin ulkomaille matkustettaessa jättimäisen
          puhelinlaskun pelossa. Silti on hyvä ymmärtää, mitä roaming tarkoittaa
          ja kuinka se toimii
        </TextArea>
        <TextArea>
          Roaming siis tarkoittaa, kuten edellisessä kappaleessa jo
          sivuttiinkin, verkkovierailua. Verkkovierailu puolestaan tarkoittaa
          sitä, että sinulla on jonkun tietyn operaattorin puhelinliittymä,
          jonka tarjoamia palveluita yleensä käytät sen verkossa. Kun menet
          matkalle toiseen maahan sen kantaman ulkopuolelle, käytätkin näitä
          oman operaattorisi tarjoamia palveluita jonkun toisen operaattorin
          verkossa, eli olet niin sanotusti vierailuverkossa. Roamingia on siis
          kaikki se toiminta, mitä teet puhelimellasi lomamatkalla Suomen
          rajojen ulkopuolella. Alla olevassa listassa on muutamia roamingiin
          liittyviä asioita, jotka sinun on hyvä tietää:
          <ul>
            <li>
              Roaming pitää sisällään myös vastaanotetut puhelut ja viestit
            </li>
            <li>
              Roamingiin EU/ETA-alueella ei vaikuta se, mikä operaattori
              toisella osapuolella on käytössään
            </li>
            <li>
              Voit ostaa prepaid-liittymällesi lisäarvoa myös roamingin aikana
            </li>
            <li>
              Mikäli prepaid-liittymän dataverkkovierailuille on asetettu
              yläraja, tulee tämän rajan olla vähintään yhtä suuri kuin
              prepaid-kortilla jäljellä oleva saldo on jaettuna 4,50 eurolla.
              Jakolasku tehdään sillä hetkellä, kun alat käyttää roamingia
            </li>
          </ul>
        </TextArea>
        <TextArea>
          Euroopan Unionin sääntöjen uudistuttua ei roamingista enää tarvitse
          maksaa ylimääräisiä maksuja, vaan voit huoletta käyttää puhelintasi
          matkustaessasi toiseen maahan Euroopan Unionin alueella samalla
          hinnalla kuin käyttäisit sitä kotimaassakin. Nämä uudistuneet EU:n
          säännöt koskevat puheluita niin kiinteisiin numeroihin kuin
          matkapuhelimiinkin, samoin kuin tekstiviestejä sekä netin käyttöä. Oma
          operaattorisi laskuttaa sinua puhelimen käytöstäsi ulkomailla saman
          hinnoittelumallin mukaisesti kuin se tekee normaalistikin ollessasi
          Suomessa. Mutta entä siinä tapauksessa, että sinulla onkin käytössäsi
          prepaid-liittymä, pätevätkö samat säännöt edelleen?
        </TextArea>
        <TextArea>
          Kyllä, periaatteessa pätevät, pienin poikkeuksin – tämä riippuu hiukan
          käytössä olevasta prepaid-liittymästä ja sen laskutustavasta. Euroopan
          Unionin säännöt pätevät sellaisenaan, mikäli liittymäsi laskutus on
          esimerkiksi vuorokausikohtaista, eikä perustu käytetyn datan määrään,
          eli esimerkiksi rajattomissa prepaid-liittymissä asiasta ei tarvitse
          huolehtia. Verkkovierailun kotimaan hintoja sovelletaan siis myös
          prepaid-liittymissä, mutta toisinaan operaattorilla voi olla käytössä
          myös dataraja. Datarajan käyttö tulee ajankohtaiseksi silloin, kun
          laskutus perustuu käytettyyn dataan, ja samaan aikaan datan hinta on
          kotimaassasi alle 4,50 euroa gigatavulta.
        </TextArea>
        <TextArea>
          EU:n alueella puhelinta ei siis tarvitse sammuttaa suurten laskujen
          pelossa; kuinka on EU:n ulkopuolelle matkustettaessa? Valitettavasti
          tähän ei ole olemassa tyhjentävää vastausta, vaan se riippuu maasta,
          jonne olet matkustamassa. Ainut yleispätevä ohje on, että asia
          kannattaa tarkistaa ennen matkaa. EU:n ulkopuolella hinnat voivat
          vaihdella paljonkin, ja toisissa maissa roaming on kallista. Toinen
          huomionarvoinen asia on laivalla matkustaminen. Vaikka laiva matkaisi
          kahden ETA-alueeseen kuuluvan maan väliä, on silti mahdollista, että
          matkalla mobiilipalveluja tarjotaan satelliitin kautta, jolloin ei ole
          olemassa mitään sääntelyä tai kattoa hintojen suhteen.
        </TextArea>
        <Header3>Mikä on minulle halvin liittymä?</Header3>
        <TextArea>
          Mikä kellekin on kaikista halvin liittymä, riippuu siitä, minkälainen
          puhelimen käyttäjä kukakin on, ja millaisia tarpeita käyttäjällä on
          liittymän suhteen. Mikäli katsotaan pelkästään hintalappua liittymän
          kohdalla, on halvin vaihtoehto hyvin todennäköisesti prepaid-liittymä,
          sillä siinä ei ole kuukausimaksuja, ja puhelut ja viestit ovat
          suunnilleen saman hintaisia kuin jälkikäteen laskutettavissa
          liittymissä. Lisäksi osassa prepaid-liittymiä on olemassa erilaisia
          bonusjärjestelmiä, jotka kasvattavat saldoa latauksen yhteydessä tai
          liittymisetuna, jolloin pelkän avauspaketin sisältämällä saldolla tai
          ensimmäisellä latauksella voi puhua pitkäänkin.
        </TextArea>
        <TextArea>
          Prepaidin peruspaketilla ei netistä tarvitse maksaa erikseen, jos
          kokee, että sitä ei käytä tai käyttää vain hyvin harvoin. Mikäli
          ajattelee, että ei halua maksaa netistä liittymän yhteydessä, on
          prepaid-liittymä melkeinpä ainoa, joka ei automaattisesti sisällä
          netin käyttöä. Toiselta kantilta asiaa katsoessa taas lähes kaikkiin
          edullisiin perusliittymiinkin sisältyy nykyään jo rajaton – vaikkakin
          usein hidas – netti, joten asialla ei ole sinänsä suurta merkitystä.
          Eli mikäli et tarvitse nettiä puhelimessa ja et kuitenkaan halua ostaa
          prepaid-liittymää syystä tai toisesta, kannattaa valita jokin
          perusliittymä.
        </TextArea>
        <TextArea>
          Perusliittymissä on monesti rajaton netti, mutta niiden hitaampia
          yhteys tekee niistä edullisempia. Perusliittymissäkin on
          kuukausimaksu, mutta se on alimmillaan 6-7 euroa. Tämän lisäksi niissä
          maksetaan myös puheluista ja viesteistä erikseen, joten vähän puhuvan
          ja nettiä tarvitsemattoman kannattaa vakavasti harkita
          kuukausimaksuttoman prepaid-liittymän hankkimista. Sen saa
          halvimmillaan alle viidellä eurolla, ja usein saldoa on
          aloituspaketissa enemmän, kuin mitä koko liittymä maksaa. Mikäli
          puolestaan haluat mahdollisimman paljon mahdollisimman edullisesti, on
          taloudellinen vaihtoehto todennäköisesti päätyä johonkin liittymään,
          joka yhdistelee kaikkia ominaisuuksia.
        </TextArea>
        <TextArea>
          Tämä tarkoittaa, että jos et tarvitse kaikkein nopeinta nettiä, ja
          olet niin sanotusti peruskäyttäjä, tulet todennäköisesti pärjäämään
          melko hyvin netillä, jonka maksiminopeus on 150-200 megabittiä
          sekunnissa. Tämä laskee liittymän kuukausittaista hintaa huomattavasti
          verrattuna kaikkein nopeimpiin liittymiin, jotka tarjoavat 450
          megabitistä sekunnissa ylöspäin nousevia nopeuksia. Esimerkiksi Telian
          Rajaton 100 M on hyvä perusliittymä, jossa on nopeudella 100
          megabittiä sekunnissa toimiva netti, 120 kappaletta viestejä/minuuttia
          puhetta, ja 12 gigatavun EU-datakäyttö. Hintaa tällä liittymällä on
          ensimmäisen vuoden ajan 26,90 euroa kuussa.
        </TextArea>
        <Header2>Yhteenveto</Header2>
        <TextArea>
          Kuten tämän artikkelin luettuasi varmasti voit todeta,
          puhelinliittymiin ja niiden valintaan liittyy paljon erilaisia
          asioita. Tässä jutussa on pyritty selventämään näitä asioita, niin
          että seuraavan kerran liittymää valitessasi sinulla olisi melko hyvä
          peruskäsitys markkinoilla olevista tuotteista sekä siitä, mitkä asiat
          vaikuttavat liittymän hintaan, kuinka toimia puhelimen kanssa
          ulkomaille matkustettaessa ja kuinka oma liittymä käyttäytyy
          matkoilla.
        </TextArea>
        <TextArea>
          Prepaid-liittymät jakavat mielipiteitä. Hyvinä puolina on edullinen
          hinta ja helppo saatavuus sekä huolettomuus. Huonoina puolina taas
          voidaan pitää liittymän latauksen muistamista. Muutenkin
          puhelinliittymät ovat melko henkilökohtaisten mieltymysten mukainen
          asia. Se mikä sopii toiselle täydellisesti, voi toiselle olla liikaa
          tai liian vähän. Hyvä puoli on, että valinnanvaraa riittää, ja
          liittymän vaihtaminen tai päivittäminen on nykyään todella helppoa.
        </TextArea>
        <TextArea>
          Operaattorit pyrkivät jatkuvasti kehittämään palveluitaan ja
          tuotteitaan sekä olemaan edellä kilpailijoitaan. Asiakkaat voivat
          nauttia tästä kilpailusta laskevien hintojen ja uusien tuotteiden
          muodossa. Nyt jo voi saada esimerkiksi SIM-kortin e-muodossa, jolloin
          varsinaista muovista korttia ei enää tarvitse. Monet operaattorit myös
          tarjoavat edullisempia liittymähintoja nuorille aikuisille, joten
          mikäli olet alle 28-vuotias, kannattaa pitää silmät auki myös näiden
          tarjousten osalta.
        </TextArea>
      </Container>
    </GlobalStyles>
  )
}

export const query = graphql`
  query {
    allPrepaidJson {
      nodes {
        id
        img
        internet
        internetSpeed
        name
        openSaldo
        price
        textPrice
        url
        callPrice
        textPrice
      }
    }
  }
`

export default FrontPage
