import React from "react"
import styled from "styled-components"
import { device } from "../../utils/mediaQueries"
import OperatorImage from "../images/OperatorImage"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { createRedirectUrl } from "../../utils/redirectHelpers/modifyUrlHelpers"

const Card = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-top: 1px solid lightgray;
  padding: 25px;
  text-align: center;
  align-items: flex-start;
  grid-gap: 20px;
  @media ${device.tablet} {
    grid-template-columns: repeat(1, 1fr);
    background-color: white;
    padding: 25px 10px;
  }
`

const Plan = styled.div`
  display: block;
`

const OrderButton = styled.button`
  padding: 15px 25px;
  background-color: #e87e01;
  border: none;
  outline: none;
  color: white;
  font-size: 17px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  :hover {
    background-color: #f16603;
  }
`

const CardItem = styled.div`
  display: grid;
  grid-gap: 10px;
`

const BoldFont = styled.div`
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
`

const LightFont = styled.div`
  font-size: 15px;
  font-weight: 200;
  color: #565656;
  text-transform: uppercase;
`

const PriceTag = styled.span`
  font-size: 14px;
  text-transform: lowercase;
  font-weight: 200;
`

const PriceText = styled.div`
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
`

const PlanName = styled.div`
  margin: 0px 20px;
`

const PrepaidCard = props => {
  return (
    <Card>
      <Plan>
        <OperatorImage imgName={props.img} />
        <PlanName>{props.name}</PlanName>
      </Plan>
      <CardItem>
        {props.save ? (
          <LightFont>Tällä käytöllä maksat</LightFont>
        ) : (
          <LightFont>Hinta</LightFont>
        )}
        <BoldFont>
          {props.price}€ <PriceTag> aloitusmaksu</PriceTag>
        </BoldFont>
      </CardItem>
      <CardItem>
        <LightFont>Puhe</LightFont>
        <PriceText>
          {props.calls}
          <PriceTag> €/min</PriceTag>
        </PriceText>
      </CardItem>
      <CardItem>
        <LightFont>Teksiviestit</LightFont>
        <PriceText>
          {props.texts}
          <PriceTag>€/kpl</PriceTag>
        </PriceText>
      </CardItem>
      <CardItem>
        <LightFont>Internetin nopeus</LightFont>
        <PriceText>
          {props.speed} <PriceTag> Mbit/s</PriceTag>
        </PriceText>
        <PriceTag>
          {" "}
          0,99€/vrk niiltä vuorokausilta kun nettiä käytetään
        </PriceTag>
      </CardItem>
      <CardItem>
        <LightFont>Saldo sisältyy</LightFont>
        <PriceText>{props.openSaldo}€</PriceText>
      </CardItem>
      <OutboundLink
        href={createRedirectUrl(props.pageName, props.name)}
        target="blank"
        rel="nofollow"
      >
        <OrderButton>Tutustu ></OrderButton>
      </OutboundLink>
    </Card>
  )
}

export default PrepaidCard
